import React, { ReactNode } from "react";
import BaseComponent from "../../../base/base-component";
import DetailsContainer from "../container/details-container";
import DetailsItem from "../item/details-item";
import { IParticipationDetailsProperties } from "./participation-details-interface";

export default class ParticipationDetails extends BaseComponent<IParticipationDetailsProperties> {
  protected declareTranslateCollection(): string {
    return "participationDetails";
  }

  public render(): ReactNode {
    const { general, primary } = this.props;

    return (
      <DetailsContainer>
        <h3 className="c-score__title">{this.translate("generalElections")}</h3>
        <DetailsItem item={general} showAverage={false} />
        <h3 className="c-score__title">{this.translate("primaryElections")}</h3>
        <DetailsItem item={primary} showAverage={false} />
      </DetailsContainer>
    );
  }
}
