import { graphql } from 'gatsby';
import React, { ReactNode } from 'react';
import { generateUrlForStates } from '../../../utils';
import BaseComponent from '../../base/base-component';
import { ICyclePercentChartPoint } from '../../components/charts/cycle-percent/cycle-percent-interface';
import { ICycleScoreChartPoint } from '../../components/charts/cycle-score/cycle-score-interface';
import ContainerBox from '../../components/container-box/container-box';
import CriteriaGrade from '../../components/criteria-grade/criteria-grade';
import ParticipationDetails from '../../components/details/participation/participation-details';
import ImproveBox from '../../components/improve-box/improve-box';
import Share from '../../components/share/share';
import SocialMetadata from '../../components/social-metadata/social-metadata';
import { SocialMetadataType } from '../../components/social-metadata/social-metadata-enum';
import StateLayout from '../../components/state-layout/state-layout';
import { ISimilarStatesItem } from '../../components/states/similar-states/item/item-interface';
import SimilarStates from '../../components/states/similar-states/similar-states';
import {
  SODComponents,
  SODCycles,
  SODGrade,
  SODMeasures,
} from '../../global/global-enum';
import { ISODNode } from '../../global/global-interfaces';
import { IStateParticipationTemplateProperties } from './state-participation-interface';

export default class StateParticipationTemplate extends BaseComponent<IStateParticipationTemplateProperties> {
  protected declareTranslateCollection(): string | undefined {
    return 'stateParticipationTemplate';
  }

  public render(): ReactNode {
    const { data, pageContext } = this.props;
    const {
      participationGeneral,
      participationPrimary,
      participationTotal,
      lesserScoreSimilarStates,
      greaterScoreSimilarStates,
      icon,
    } = data;
    const { breadCrumbs, state, pageContent } = pageContext;
    const {
      participationScoreDesc1,
      participationScoreDesc2,
      improveParticipationDesc,
      improveParticipationSolution1,
      improveParticipationSolution2,
      improveParticipationSolution3,
      generalElectionDesc,
      primaryElectionDesc,
    } = pageContent;

    const participationAverage = this.populateAverageData(SODComponents.TOTAL);
    const generalAverage = this.populateAverageData(
      SODComponents.PARTICIPATION_GENERAL_ELECTION
    );
    const primaryAverage = this.populateAverageData(
      SODComponents.PARTICIPATION_PRIMARY_ELECTION
    );

    const participationOverall = this.populateOverallData(SODComponents.TOTAL);
    const generalOverall = this.populateOverallData(
      SODComponents.PARTICIPATION_GENERAL_ELECTION
    );
    const primaryOverall = this.populateOverallData(
      SODComponents.PARTICIPATION_PRIMARY_ELECTION
    );

    const totalCycleScores: ICycleScoreChartPoint[] =
      participationTotal.nodes.map((node) => {
        return {
          cycle: node.cycle,
          score: Math.round(node.score * 100),
          grade: node.grade,
          weight: node.weight,
        };
      });
    const primaryCyclePercents: ICyclePercentChartPoint[] =
      participationPrimary.nodes.map((node) => {
        return {
          cycle: node.cycle,
          percent: Math.round(node.score * 100),
        };
      });
    const generalCyclePercents: ICyclePercentChartPoint[] =
      participationGeneral.nodes.map((node) => {
        return {
          cycle: node.cycle,
          percent: Math.round(node.score * 100),
        };
      });

    const lesserScoreSimilarStatesItems = lesserScoreSimilarStates.nodes.map(
      (node): ISimilarStatesItem => {
        return {
          abbreviation: node.code,
          score: Math.round(node.score * 100),
          title: node.state,
          grade: node.grade,
          itemLink: `/state/${generateUrlForStates(node.state)}/participation`,
        };
      }
    );

    const greaterScoreSimilarStatesItems = greaterScoreSimilarStates.nodes.map(
      (node): ISimilarStatesItem => {
        return {
          abbreviation: node.code,
          score: Math.round(node.score * 100),
          title: node.state,
          grade: node.grade,
          itemLink: `/state/${generateUrlForStates(node.state)}/participation`,
        };
      }
    );

    const stateUrl: string = generateUrlForStates(state);

    const { publicURL: iconUrl = '' } = icon.nodes[0];

    return (
      <StateLayout
        state={state}
        image={iconUrl}
        breadCrumbItems={breadCrumbs}
        title={`${this.translate('title')}(${participationOverall.state})`}
        description={this.translate('firstScoreDescription')}
      >
        <SocialMetadata
          type={SocialMetadataType.SUMMARY}
          title={`${participationOverall.state} ${this.translate('title')}`}
          description={this.translate('firstScoreDescription')}
          imageResourceFileName={`embedded-participation-score-${generateUrlForStates(
            participationOverall.state
          )}.png`}
        />
        <ContainerBox
          className="c-section--score is-criteria-grade"
          id="participation"
        >
          <div className="c-score">
            <CriteriaGrade
              title={this.translate('scoreTitle')}
              averageScore={Math.round(participationAverage.value * 100)}
              averagePercent={Math.round(participationAverage.value * 100)}
              overallPercentage={Math.round(participationOverall.value * 100)}
              cycleScores={totalCycleScores}
              overallGrade={participationOverall.grade}
              overallScore={Math.round(participationOverall.score * 100)}
              descriptions={[participationScoreDesc1, participationScoreDesc2]}
              simpleMode={true}
            />
            <Share
              absoluteLink={`/state/${stateUrl}/participation`}
              embeddedLink={`/embedded/participation/${stateUrl}`}
              imageResourceFileName={`embedded-participation-score-${generateUrlForStates(
                participationOverall.state
              )}.png`}
            />
          </div>
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--similar">
          <SimilarStates
            lesserScoreStates={lesserScoreSimilarStatesItems}
            greaterScoreStates={greaterScoreSimilarStatesItems}
            activeState={{
              abbreviation: participationOverall.code,
              grade: participationOverall.grade,
              score: Math.round(participationOverall.score * 100),
              title: participationOverall.state,
              itemLink: `/state/${generateUrlForStates(
                participationOverall.state
              )}/participation`,
            }}
          />
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--improve">
          <ImproveBox
            improveBoxItems={{
              title: this.translate('improveParticipation'),
              description: improveParticipationDesc,
              items: [
                improveParticipationSolution1,
                improveParticipationSolution2,
                improveParticipationSolution3,
              ],
            }}
          />
        </ContainerBox>
        <ContainerBox className="c-section-box c-section--details">
          <ParticipationDetails
            general={{
              title: this.translate('usAverage'),
              description: generalElectionDesc,
              value: Math.round(generalAverage.value * 100),
              averageValue: Math.round(generalAverage.value * 100),
              chartData: generalCyclePercents,
            }}
            primary={{
              title: this.translate('usAverage'),
              description: primaryElectionDesc,
              value: Math.round(primaryAverage.value * 100),
              averageValue: Math.round(primaryAverage.value * 100),
              chartData: primaryCyclePercents,
            }}
          />
        </ContainerBox>
      </StateLayout>
    );
  }

  private populateOverallData(component: SODComponents): ISODNode {
    const { overall } = this.props.data;
    const componentOverall: ISODNode = overall.nodes.find((node) => {
      return node.component === component;
    }) ?? {
      code: 'NA',
      component,
      cycle: SODCycles.OVERALL,
      grade: SODGrade.GRADE_NA,
      measure: SODMeasures.PARTICIPATION,
      score: -1,
      state: 'NA',
      value: -1,
      weight: -1,
    };

    return componentOverall;
  }

  private populateAverageData(component: SODComponents): ISODNode {
    const { average } = this.props.data;
    const componentAverage: ISODNode = average.nodes.find((node) => {
      return node.component === component;
    }) ?? {
      code: 'NA',
      component,
      cycle: SODCycles.OVERALL,
      grade: SODGrade.GRADE_NA,
      measure: SODMeasures.PARTICIPATION,
      score: -1,
      state: 'NA',
      value: -1,
      weight: -1,
    };
    return componentAverage;
  }
}

export const query = graphql`
  query StateParticipation($state: String, $score: Float) {
    participationTotal: allSheetDataParticipation(
      filter: {
        state: { eq: $state }
        component: { eq: "Total" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
        value
        weight
      }
    }

    participationGeneral: allSheetDataParticipation(
      filter: {
        state: { eq: $state }
        component: { eq: "General" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
      }
    }

    participationPrimary: allSheetDataParticipation(
      filter: {
        state: { eq: $state }
        component: { eq: "Primary" }
        cycle: { ne: "Overall" }
      }
    ) {
      nodes {
        code
        cycle
        grade
        score
        state
        measure
        component
      }
    }

    lesserScoreSimilarStates: allSheetDataParticipation(
      sort: { fields: score, order: DESC }
      filter: {
        state: { ne: $state }
        component: { eq: "Total" }
        cycle: { eq: "Overall" }
        score: { lte: $score }
        abbrev: { ne: "US Avg" }
      }
      limit: 3
    ) {
      nodes {
        code
        score
        state
        grade
      }
    }

    greaterScoreSimilarStates: allSheetDataParticipation(
      sort: { fields: score, order: ASC }
      filter: {
        state: { ne: $state }
        component: { eq: "Total" }
        cycle: { eq: "Overall" }
        score: { gte: $score }
        abbrev: { ne: "US Avg" }
      }
      limit: 3
    ) {
      nodes {
        code
        score
        state
        grade
      }
    }

    average: allSheetDataParticipation(
      filter: { state: { eq: "US Average" }, cycle: { eq: "Overall" } }
    ) {
      nodes {
        code
        state
        grade
        component
        measure
        cycle
        score
        value
        weight
      }
    }

    overall: allSheetDataParticipation(
      filter: { state: { eq: $state }, cycle: { eq: "Overall" } }
    ) {
      nodes {
        code
        state
        grade
        component
        measure
        cycle
        score
        value
        weight
      }
    }
    icon: allFile(
      filter: {
        name: { eq: $state }
        ext: { eq: ".svg" }
        relativeDirectory: { eq: "images/states" }
      }
    ) {
      nodes {
        name
        publicURL
        relativeDirectory
      }
    }
  }
`;
